import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import LetoyService from "@/utils/LetoyService";


const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'MainLayout',
        component: () => import(/* webpackChunkName: "about" */ '../layout/MainLayout.vue'),
        children: [
            {
                path: '/',
                name: 'home',
                meta: {
                    name: '首頁',
                    value: 'home',
                    needLanguageBox: true,
                    needHeadBarBackground: false,
                    needMenu: true
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
            },
            {
                path: '/event-insight',
                name: 'EventInsight',
                meta: {
                    name: 'EventInsight',
                    value: 'event_insight',
                    needLanguageBox: true,
                    needHeadBarBackground: false,
                    needMenu: true
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/EventInsight.vue')
            },
            {
                path: '/stamp-page',
                name: 'StampPage',
                meta: {
                    name: 'StampPage',
                    value: 'stamp_page',
                    needLanguageBox: true,
                    needHeadBarBackground: false,
                    needMenu: true
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/StampPage.vue')
            },
            {
                path: '/lottery-page',
                name: 'LotteryPage',
                meta: {
                    name: 'LotteryPage',
                    value: 'lottery_page',
                    needLanguageBox: true,
                    needHeadBarBackground: false,
                    needMenu: false
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/LotteryPage.vue')
            },
            {
                path: '/activity',
                name: 'activity',
                meta: {value: 'activity', needLanguageBox: true, needHeadBarBackground: false, needMenu: true},
                component: () => import(/* webpackChunkName: "about" */ '../views/ActivityPage.vue')
            },
            {
                path: '/task',
                name: 'task',
                meta: {value: 'task', needLanguageBox: true, needHeadBarBackground: false, needMenu: false},
                component: () => import(/* webpackChunkName: "about" */ '../views/TaskPage.vue')
            },
            {
                path: '/quiz',
                name: 'quiz',
                meta: {value: 'quiz', needLanguageBox: true, needHeadBarBackground: false, needMenu: true},
                component: () => import(/* webpackChunkName: "about" */ '../views/QuizPage.vue')
            },
            {
                path: '/login',
                name: 'PhoneLogin',
                meta: {value: 'phone_login', needLanguageBox: true, needHeadBarBackground: false, needMenu: true},
                component: () => import(/* webpackChunkName: "about" */ '../views/PhoneLogin.vue')
            },
            {
                path: '/merchant-info',
                name: 'MerchantInfo',
                meta: {value: 'merchant_info', needLanguageBox: true, needHeadBarBackground: false, needMenu: true},
                component: () => import(/* webpackChunkName: "about" */ '../views/MerchantInfo.vue')
            },
            {
                path: '/interactive',
                name: 'InteractivePage',
                meta: {value: 'interactive', needLanguageBox: false, needHeadBarBackground: false, needMenu: true},
                component: () => import(/* webpackChunkName: "about" */ '../views/InteractivePage.vue')
            },
            {
                path: '/rules',
                name: 'rules',
                meta: {value: 'rules', needLanguageBox: true, needHeadBarBackground: false, needMenu: true},
                component: () => import(/* webpackChunkName: "about" */ '../views/Rules.vue')
            },
            {
                path: '/bag',
                name: 'bag',
                meta: {value: 'bag', needLanguageBox: true, needHeadBarBackground: false, needMenu: false},
                component: () => import(/* webpackChunkName: "about" */ '../views/MyBag.vue')
            },
        ]
    },
    {
        path: '/game-page',
        name: 'GamePage',
        component: () => import(/* webpackChunkName: "about" */ '../views/GamePage.vue')
    },
    {
        path: '/stamp-test',
        name: 'StampTestPage',
        component: () => import(/* webpackChunkName: "about" */ '../views/StampTestPage.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'PageNotFound',
        component: () => import(/* webpackChunkName: "about" */ '../views/PageNotFound.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {

        let url = "";
        switch (to.fullPath) {
            case '/':
                url = 'VisitApp';
                break;
            case '/event-insight':
                url = 'VisitEventInsightPage';
                break;
            case '/stamp-page':
                url = 'VisitStampPage';
                break;
            case '/activity':
                url = 'VisitActivityPage';
                break;
            case '/lottery-page':
                url = 'VisitLotteryPage';
                break;
            case '/task':
                url = 'VisitTaskPage';
                break;
            case '/merchant-info':
                url = 'VisitMerchantInfoPage';
                break;
            case '/quiz':
                url = 'VisitQuizPage';
                break;
            case '/interactive':
                url = 'VisitInteractivePage';
                break;
            case '/rules':
                url = 'VisitRulesPage';
                break;
            case '/bag':
                url = 'VisitBagPage';
                break;
            default :
                url = ''
                break;
        }
        if (url !== '') {
            LetoyService.authNoCheckRequest(`/UserActionRecord/${url}`, {}, {}, () => {
            }, () => {
            }).then(() => {
            })
        }

        return {top: 0};
    }
})

export default router
